import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/yuncut_q/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    name: 'login',
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__operation__Login__model.ts' */ 'D:/yuncut_q/src/pages/operation/Login/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__operation__Login" */ '../operation/Login'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../operation/Login').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'print',
    path: '/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'layouts__operation__model.ts' */ 'D:/yuncut_q/src/layouts/operation/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "layouts__operation__PrintLayout" */ '../../layouts/operation/PrintLayout'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/operation/PrintLayout').default,
    hideInMenu: true,
    routes: [
      {
        name: 'print',
        path: '/print/deliver',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__deliver__Print" */ '../operation/deliver/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/deliver/Print').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/prod-order',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__order__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodOrder/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__order__prodOrder__Print" */ '../operation/order/prodOrder/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/order/prodOrder/Print').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/excess-stock',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__order__program__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/program/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__order__program__Detail__SteelPlate__Print" */ '../operation/order/program/Detail/SteelPlate/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/order/program/Detail/SteelPlate/Print')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/steel-plate',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__materialLibrary__materialScience__steelPlate__Print" */ '../operation/materialLibrary/materialScience/steelPlate/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/materialLibrary/materialScience/steelPlate/Print')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/steel-plates',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__steelPlate__List__Prints__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/steelPlate/List/Prints/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__materialLibrary__materialScience__steelPlate__List__Prints" */ '../operation/materialLibrary/materialScience/steelPlate/List/Prints'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/materialLibrary/materialScience/steelPlate/List/Prints')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/steel-plate-centre',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__centreLibrary__centreScience__steelPlate__Print" */ '../operation/centreLibrary/centreScience/steelPlate/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/centreLibrary/centreScience/steelPlate/Print')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/steel-plates-centre',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__steelPlate__List__Prints__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/steelPlate/List/Prints/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__centreLibrary__centreScience__steelPlate__List__Prints" */ '../operation/centreLibrary/centreScience/steelPlate/List/Prints'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/centreLibrary/centreScience/steelPlate/List/Prints')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/centre-surplus',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__centreLibrary__centreSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreSurplus/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__centreLibrary__centreSurplus__steelPlate__Print" */ '../operation/centreLibrary/centreSurplus/steelPlate/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/centreLibrary/centreSurplus/steelPlate/Print')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/material-surplus',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__materialLibrary__materialSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSurplus/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__materialLibrary__materialSurplus__steelPlate__Print" */ '../operation/materialLibrary/materialSurplus/steelPlate/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/materialLibrary/materialSurplus/steelPlate/Print')
              .default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/order-print',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__order__salesOrder__Print" */ '../operation/order/salesOrder/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/order/salesOrder/Print').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/prod-plan',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__order__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlan/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__order__prodPlan__Print" */ '../operation/order/prodPlan/Print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/order/prodPlan/Print').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/contract-print',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__order__salesOrder__Contract" */ '../operation/order/salesOrder/Contract'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/order/salesOrder/Contract').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/claim',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__postSale__claimManagement__print" */ '../operation/postSale/claimManagement/print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/postSale/claimManagement/print').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'print',
        path: '/print/afterSaleOrder',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__postSale__afterSaleList__print" */ '../operation/postSale/afterSaleList/print'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/postSale/afterSaleList/print').default,
        hideInMenu: true,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'layouts__operation__model.ts' */ 'D:/yuncut_q/src/layouts/operation/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../../layouts/operation/OperationLayout'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/operation/OperationLayout').default,
    authority: ['ROLE_OPERATION'],
    routes: [
      {
        name: 'home',
        icon: 'home',
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__Overview__models__operationStatisticsView.ts' */ 'D:/yuncut_q/src/pages/operation/Overview/models/operationStatisticsView.ts').then(
                  m => {
                    return {
                      namespace: 'operationStatisticsView',
                      ...m.default,
                    };
                  },
                ),
                import(/* webpackChunkName: 'p__operation__Overview__models__statisticsFilter.ts' */ 'D:/yuncut_q/src/pages/operation/Overview/models/statisticsFilter.ts').then(
                  m => {
                    return { namespace: 'statisticsFilter', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__Overview" */ '../operation/Overview'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/Overview').default,
        exact: true,
      },
      {
        name: 'pdfview',
        path: '/pdfview',
        icon: 'home',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__operation__PdfView" */ '../operation/PdfView'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/PdfView').default,
        exact: true,
      },
      {
        name: 'inquiry',
        icon: 'solution',
        path: '/inquiry',
        routes: [
          {
            path: '/inquiry',
            redirect: '/inquiry/first',
            exact: true,
          },
          {
            name: 'first',
            icon: 'team',
            path: '/inquiry/first',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__inquiry__First__model.ts' */ 'D:/yuncut_q/src/pages/operation/inquiry/First/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__inquiry__model.ts' */ 'D:/yuncut_q/src/pages/operation/inquiry/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__inquiry__First" */ '../operation/inquiry/First'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/inquiry/First').default,
            exact: true,
          },
          {
            name: 'list',
            path: '/inquiry/list',
            icon: 'profile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__inquiry__model.ts' */ 'D:/yuncut_q/src/pages/operation/inquiry/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__inquiry__List" */ '../operation/inquiry/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/inquiry/List').default,
            exact: true,
          },
          {
            name: 'change',
            path: '/inquiry/change',
            icon: 'profile',
            hideInMenu: true,
            showBack: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__inquiry__model.ts' */ 'D:/yuncut_q/src/pages/operation/inquiry/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__inquiry__Change" */ '../operation/inquiry/Change'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/inquiry/Change').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/inquiry/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__inquiry__model.ts' */ 'D:/yuncut_q/src/pages/operation/inquiry/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__inquiry__Detail" */ '../operation/inquiry/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/inquiry/Detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'order',
        icon: 'cluster',
        path: '/order',
        routes: [
          {
            path: '/order',
            redirect: '/order/sale-order',
            exact: true,
          },
          {
            name: 'sale-order',
            icon: 'shopping',
            path: '/order/sale-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/sale-order',
                redirect: '/order/sale-order/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/sale-order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/salesOrder/OrderList'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/salesOrder/OrderList').default,
                exact: true,
              },
              {
                name: 'detail',
                path: '/order/sale-order/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/salesOrder/OrderDetail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/salesOrder/OrderDetail')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'applyChange',
                path: '/order/sale-order/applyChange',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/ApplyChange'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/ApplyChange')
                      .default,
                hideInMenu: true,
                exact: true,
              },
              {
                name: 'partChangeTable',
                path: '/order/sale-order/partChangeTable',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__ApplyChange__PartChangeTable__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/ApplyChange/PartChangeTable/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/ApplyChange/PartChangeTable/HandsontableExcel'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/ApplyChange/PartChangeTable/HandsontableExcel')
                      .default,
                hideInMenu: true,
                hideInNavTabs: true,
                exact: true,
              },
              {
                name: 'partChangeSubmit',
                path: '/order/sale-order/partChangeSubmit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/ApplyChange/ChangePartSubmit'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/ApplyChange/ChangePartSubmit')
                      .default,
                hideInMenu: true,
                hideInNavTabs: true,
                exact: true,
              },
              {
                name: 'allocation',
                path: '/order/sale-order/allocation',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlan/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodPlan/Form'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodPlan/Form').default,
                showBack: true,
                exact: true,
              },
              {
                name: 'examine-deliver',
                path: '/order/sale-order/examine-deliver',
                exact: true,
              },
              {
                name: 'batch-collection',
                path: '/order/sale-order/batch-collection',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/salesOrder/BatchCollectionForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/salesOrder/BatchCollectionForm')
                      .default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'changeOrder',
            icon: 'home',
            path: '/order/changeOrder',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/changeOrder',
                redirect: '/order/changeOrder/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/changeOrder/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder').default,
                exact: true,
              },
              {
                name: 'approvalInfo',
                path: '/order/changeOrder/approvalInfo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/ApprovalChange'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/ApprovalChange')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'offerInfo',
                path: '/order/changeOrder/offerInfo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/OfferChange'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/OfferChange')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'changeContent',
                path: '/order/changeOrder/changeContent',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/OrderChangeContent'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/OrderChangeContent')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'distributionInfo',
                path: '/order/changeOrder/distributionInfo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/DistributionChange'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/DistributionChange')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'factoryChangeIndex',
                path: '/order/changeOrder/factoryChangeIndex',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/FactoryChange'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/FactoryChange')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'prod-plan',
            icon: 'apartment',
            path: '/order/prod-plan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-plan',
                redirect: '/order/prod-plan/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-plan/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlan/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodPlan/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodPlan/List').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/prod-plan/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlan/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodPlan/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodPlan/Detail').default,
                showBack: true,
                exact: true,
              },
              {
                name: 'refuseDetail',
                icon: 'home',
                path: '/order/prod-plan/refuseDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodPlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlan/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodPlan/refuseDetail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodPlan/refuseDetail').default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'task',
            icon: 'read',
            path: '/order/task',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/task',
                redirect: '/order/task/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/task/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__task__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/task/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/task/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/task/List').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/task/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__task__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/task/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/task/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/task/Detail').default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'program',
            icon: 'laptop',
            path: '/order/program',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/program',
                redirect: '/order/program/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/program/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__program__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/program/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/program/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/program/List').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/program/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__program__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/program/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/program/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/program/Detail').default,
                showBack: true,
                exact: true,
              },
              {
                name: 'erpDetail',
                icon: 'home',
                path: '/order/program/erpDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__program__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/program/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/program/erpLogDetail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/program/erpLogDetail').default,
                showBack: true,
                exact: true,
              },
              {
                name: 'editProgramCuttingTimes',
                icon: 'home',
                path: '/order/program/editProgramCuttingTimes',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__program__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/program/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/program/editProgramCuttingTimes'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/program/editProgramCuttingTimes')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'prod-order',
            icon: 'printer',
            path: '/order/prod-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-order',
                redirect: '/order/prod-order/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodOrder/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodOrder/List').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/prod-order/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodOrder/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodOrder/Detail').default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'procedure-order',
            icon: 'printer',
            path: '/order/procedure-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/procedure-order',
                redirect: '/order/procedure-order/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/procedure-order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__procedureOrder__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/procedureOrder/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/procedureOrder/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/procedureOrder/List').default,
                exact: true,
              },
            ],
          },
          {
            name: 'payment-order',
            icon: 'printer',
            path: '/order/payment-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/payment-order',
                redirect: '/order/payment-order/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/payment-order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__paymentOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/paymentOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/paymentOrder/OrderList'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/paymentOrder/OrderList')
                      .default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/payment-order/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__paymentOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/paymentOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/paymentOrder/OrderDetail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/paymentOrder/OrderDetail')
                      .default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'customer-order',
            icon: 'shopping',
            path: '/order/customer-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/customer-order',
                redirect: '/order/customer-order/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/customer-order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__customerOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/customerOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/customerOrder/OrderList'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/customerOrder/OrderList')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'order-track',
            icon: 'select',
            path: '/order/order-track',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/order-track',
                redirect: '/order/order-track/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/order/order-track/list',
                icon: 'select',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__orderTrack__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/orderTrack/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/orderTrack/list'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/orderTrack/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'materialChange',
            icon: 'select',
            path: '/order/materialChange',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/materialChange',
                redirect: '/order/materialChange/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/order/materialChange/list',
                icon: 'select',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__MaterialChangeReport__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/MaterialChangeReport/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__order__ChangeOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/ChangeOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/ChangeOrder/MaterialChangeReport'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/ChangeOrder/MaterialChangeReport')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'prod-ankePlan',
            icon: 'apartment',
            path: '/order/prod-ankePlan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-ankePlan',
                redirect: '/order/prod-ankePlan/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-ankePlan/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__order__prodAnkePlan__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodAnkePlan/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/order/prodAnkePlan/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/order/prodAnkePlan/List').default,
                exact: true,
              },
            ],
          },
          {
            name: 'prod-plan-detail',
            icon: 'apartment',
            path: '/order/prod-plan-detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__order__prodPlanDetail__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodPlanDetail/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__order__prodPlanDetail" */ '../operation/order/prodPlanDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/order/prodPlanDetail').default,
            exact: true,
          },
          {
            name: 'sales-order-detail',
            icon: 'apartment',
            path: '/order/sales-order-detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__order__salesOrderDetail__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrderDetail/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__order__salesOrderDetail" */ '../operation/order/salesOrderDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/order/salesOrderDetail').default,
            exact: true,
          },
        ],
      },
      {
        name: 'workProcess',
        icon: 'solution',
        path: '/workProcess',
        routes: [
          {
            name: 'partsList',
            icon: 'home',
            path: '/workProcess/parts-order/partsList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__workProcess__partsProcess__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/partsProcess/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__workProcess__partsProcess__Form" */ '../operation/workProcess/partsProcess/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/workProcess/partsProcess/Form').default,
            exact: true,
          },
          {
            path: '/workProcess',
            redirect: '/workProcess/list',
            exact: true,
          },
          {
            name: 'list',
            path: '/workProcess/workProcess-order/list',
            icon: 'profile',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__workProcess__workProcessOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/workProcessOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__workProcess__workProcessOrder" */ '../operation/workProcess/workProcessOrder'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/workProcess/workProcessOrder').default,
            exact: true,
          },
          {
            name: 'invoiceManage',
            icon: 'PropertySafety',
            path: '/workProcess/invoiceManage',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/workProcess/invoiceManage',
                redirect: '/workProcess/invoiceManage/list',
                exact: true,
              },
              {
                name: 'invoiceList',
                icon: 'home',
                path: '/workProcess/invoiceManage/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__workProcess__invoiceManage__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/invoiceManage/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/workProcess/invoiceManage/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/workProcess/invoiceManage/List')
                      .default,
                exact: true,
              },
              {
                name: 'invoiceList',
                icon: 'home',
                path: '/workProcess/invoiceManage/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__workProcess__invoiceManage__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/invoiceManage/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/workProcess/invoiceManage/List/PartSalesInvoice/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/workProcess/invoiceManage/List/PartSalesInvoice/Detail')
                      .default,
                exact: true,
              },
              {
                name: 'invoiceSave',
                path: '/workProcess/invoiceManage/save',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__workProcess__invoiceManage__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/invoiceManage/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/workProcess/invoiceManage/List/PartSalesInvoice/save'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/workProcess/invoiceManage/List/PartSalesInvoice/save')
                      .default,
                hideInMenu: true,
                exact: true,
              },
              {
                name: 'invoiceEdit',
                path: '/workProcess/invoiceManage/edit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__workProcess__invoiceManage__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/workProcess/invoiceManage/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/workProcess/invoiceManage/List/PartSalesInvoice/edit'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/workProcess/invoiceManage/List/PartSalesInvoice/edit')
                      .default,
                hideInMenu: true,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'deliver',
        icon: 'SendOutlined',
        path: '/deliver',
        routes: [
          {
            path: '/deliver',
            redirect: '/deliver/can-deliver-list',
            exact: true,
          },
          {
            name: 'can-deliver-list',
            icon: 'car',
            path: '/deliver/can-deliver-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__CanDeliverList__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/CanDeliverList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__CanDeliverList" */ '../operation/deliver/CanDeliverList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/CanDeliverList').default,
            exact: true,
          },
          {
            name: 'delivered-list',
            icon: 'car',
            path: '/deliver/delivered-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__DeliveredList__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/DeliveredList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__DeliveredList" */ '../operation/deliver/DeliveredList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/DeliveredList').default,
            exact: true,
          },
          {
            name: 'can-deliver-procedure-list',
            icon: 'car',
            path: '/deliver/can-deliver-procedure-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__CanDeliverProcedureList__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/CanDeliverProcedureList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__CanDeliverProcedureList" */ '../operation/deliver/CanDeliverProcedureList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/CanDeliverProcedureList').default,
            exact: true,
          },
          {
            name: 'can-deliver-mergeDocuments-list',
            icon: 'car',
            path: '/deliver/can-deliver-mergeDocuments-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__CanDeliverMergeDocumentsList__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/CanDeliverMergeDocumentsList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__CanDeliverMergeDocumentsList" */ '../operation/deliver/CanDeliverMergeDocumentsList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/CanDeliverMergeDocumentsList')
                  .default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/deliver/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__Detail" */ '../operation/deliver/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/Detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'form',
            path: '/deliver/form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__Form" */ '../operation/deliver/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'logistic-list',
            icon: 'car',
            path: '/deliver/logistic-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__logistic__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logistic/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__logistic" */ '../operation/deliver/logistic'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/logistic').default,
            exact: true,
          },
          {
            name: 'logistic-order',
            path: '/deliver/logistic-order',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__logistic__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logistic/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__logistic__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logistic/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__logistic__Form__orderindex" */ '../operation/deliver/logistic/Form/orderindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/logistic/Form/orderindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'huolala-logistic-list',
            icon: 'car',
            path: '/deliver/huolala-logistic-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__logisticHuolala__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logisticHuolala/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__logisticHuolala" */ '../operation/deliver/logisticHuolala'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/logisticHuolala').default,
            exact: true,
          },
          {
            name: 'huolala-logistic-order',
            path: '/deliver/huolala-logistic-order',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__logisticHuolala__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logisticHuolala/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__logisticHuolala__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logisticHuolala/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__logisticHuolala__Form__orderindex" */ '../operation/deliver/logisticHuolala/Form/orderindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/logisticHuolala/Form/orderindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'huolala-logistic-detail',
            path: '/deliver/material-purchase/huolala-logistic-detail',
            icon: 'gateway',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__logisticHuolala__detail__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logisticHuolala/detail/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__logisticHuolala__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/logisticHuolala/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__logisticHuolala__detail__index" */ '../operation/deliver/logisticHuolala/detail/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/logisticHuolala/detail/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'transport-list',
            icon: 'car',
            path: '/deliver/transport-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__deliver__transport__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/transport/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__deliver__model.ts' */ 'D:/yuncut_q/src/pages/operation/deliver/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__deliver__transport" */ '../operation/deliver/transport'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/deliver/transport').default,
            exact: true,
          },
        ],
      },
      {
        name: 'logistics',
        icon: 'car',
        path: '/logistics',
        routes: [
          {
            path: '/logistics',
            redirect: '/logistics/list',
            exact: true,
          },
          {
            path: '/logistics/list',
            name: 'list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logistics__model.tsx' */ 'D:/yuncut_q/src/pages/operation/logistics/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logistics__List" */ '../operation/logistics/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logistics/List').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/logistics/form',
            name: 'list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logistics__model.tsx' */ 'D:/yuncut_q/src/pages/operation/logistics/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logistics__Form" */ '../operation/logistics/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logistics/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/logistics/route',
            name: 'list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logistics__model.tsx' */ 'D:/yuncut_q/src/pages/operation/logistics/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logistics__Route" */ '../operation/logistics/Route'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logistics/Route').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'freight',
        icon: 'car',
        path: '/freight',
        routes: [
          {
            path: '/freight',
            redirect: '/freight/list',
            exact: true,
          },
          {
            path: '/freight/list',
            name: 'list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__freight__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/freight/List/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__freight__List" */ '../operation/freight/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/freight/List').default,
            hideInMenu: true,
            exact: true,
          },
        ],
      },
      {
        name: 'settlement',
        icon: 'MoneyCollect',
        path: '/settlement',
        routes: [
          {
            path: '/settlement',
            redirect: '/settlement/list',
            exact: true,
          },
          {
            path: '/settlement/list',
            name: 'list',
            icon: 'MoneyCollect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__List" */ '../operation/settlement/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/List').default,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/addMtSale',
            name: 'settlement_addMtSale',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale__materialSave" */ '../operation/settlement/settlement_mtSale/materialSave'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale/materialSave')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/addMtSaleExcelint',
            name: 'settlement_addMtSaleExcelint',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale__materialSaveExcelint" */ '../operation/settlement/settlement_mtSale/materialSaveExcelint'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale/materialSaveExcelint')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDetail',
            name: 'settlement_mtSaleDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale__MtSaleDetailed" */ '../operation/settlement/settlement_mtSale/MtSaleDetailed'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale/MtSaleDetailed')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliverDetail',
            name: 'settlement_mtSaleDeliverDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale__MtSaleDetailed__MtOrderDeliverDetailed" */ '../operation/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliver',
            name: 'settlement_mtSaleDeliver',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale__deliver" */ '../operation/settlement/settlement_mtSale/deliver'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale/deliver')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'materialTransformation-list',
            icon: 'car',
            path:
              '/settlement/materialTransformation/materialTransformation-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__materialTransformation__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/materialTransformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__materialTransformation" */ '../operation/settlement/materialTransformation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/materialTransformation')
                  .default,
            exact: true,
          },
          {
            name: 'materialTransformation-save',
            path:
              '/settlement/materialTransformation/materialTransformation-save',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__materialTransformation__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/materialTransformation/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__settlement__materialTransformation__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/materialTransformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__materialTransformation__Form__index" */ '../operation/settlement/materialTransformation/Form/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/materialTransformation/Form/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementPaymentPlan',
            name: 'settlementPaymentPlan',
            icon: 'Transaction',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__paymentPlan" */ '../operation/settlement/paymentPlan'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/paymentPlan').default,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter',
            name: 'settlementCenter',
            icon: 'MoneyCollect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlementCenter" */ '../operation/settlement/settlementCenter'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlementCenter').default,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter/settlementDetail',
            name: 'settlementDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlementCenter__settlementDetail" */ '../operation/settlement/settlementCenter/settlementDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlementCenter/settlementDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter/paymentApplyDetail',
            name: 'paymentApplyDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlementCenter__paymentApplyDetail" */ '../operation/settlement/settlementCenter/paymentApplyDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlementCenter/paymentApplyDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementCenter/paymentApply',
            name: 'settlementCenter_PaymentApply',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlementCenter__paymentApplication" */ '../operation/settlement/settlementCenter/paymentApplication'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlementCenter/paymentApplication')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementPaymentPlan/settlementDetail',
            name: 'planSettlementDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__paymentPlan__settlementDetail" */ '../operation/settlement/paymentPlan/settlementDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/paymentPlan/settlementDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/settlement/settlementInvoice',
            name: 'settlementInvoice',
            icon: 'PropertySafety',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__invoice" */ '../operation/settlement/invoice'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/invoice').default,
            exact: true,
          },
          {
            path: '/settlement/prodList',
            name: 'prodList',
            icon: 'ShareAlt',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__order__prodOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/prodOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__order__prodOrder__List" */ '../operation/order/prodOrder/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/order/prodOrder/List').default,
            exact: true,
          },
          {
            path: '/settlement/settlement_mtSale',
            name: 'settlement_mtSale',
            icon: 'Shopping',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__settlement__model.ts' */ 'D:/yuncut_q/src/pages/operation/settlement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__settlement__settlement_mtSale" */ '../operation/settlement/settlement_mtSale'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/settlement/settlement_mtSale').default,
            exact: true,
          },
          {
            path: '/settlement/partsPurchaseList',
            name: 'partsPurchaseList',
            icon: 'ShareAlt',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase" */ '../operation/productLibrary/PartsPurchase'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase').default,
            exact: true,
          },
          {
            path: '/settlement/claimList',
            name: 'claimList',
            icon: 'ShareAlt',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__claimManagement" */ '../operation/postSale/claimManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/claimManagement').default,
            exact: true,
          },
        ],
      },
      {
        name: 'supplement',
        icon: 'car',
        path: '/supplement',
        routes: [
          {
            path: '/supplement',
            redirect: '/supplement/can-supplement-list',
            exact: true,
          },
          {
            name: 'can-supplement-list',
            icon: 'car',
            path: '/supplement/can-supplement-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__CanSupplementList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/CanSupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__CanSupplementList" */ '../operation/supplement/CanSupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/CanSupplementList').default,
            exact: true,
          },
          {
            name: 'supplement-applying-list',
            icon: 'car',
            path: '/supplement/supplement-applying-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__ApplySupplementList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/ApplySupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__ApplySupplementList" */ '../operation/supplement/ApplySupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/ApplySupplementList').default,
            exact: true,
          },
          {
            name: 'reject-supplement-list',
            icon: 'car',
            path: '/supplement/reject-supplement-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__RejectSupplementList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/RejectSupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__RejectSupplementList" */ '../operation/supplement/RejectSupplementList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/RejectSupplementList').default,
            exact: true,
          },
          {
            name: 'supplemented-list',
            icon: 'car',
            path: '/supplement/supplemented-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__SupplementedList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/SupplementedList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__SupplementedList" */ '../operation/supplement/SupplementedList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/SupplementedList').default,
            exact: true,
          },
          {
            name: 'badLoss-list',
            icon: 'car',
            path: '/supplement/badLoss-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__badLossList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/badLossList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__badLossList" */ '../operation/supplement/badLossList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/badLossList').default,
            exact: true,
          },
          {
            name: 'detail',
            path: '/supplement/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__Detail" */ '../operation/supplement/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/Detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'apply-form',
            path: '/supplement/form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__Form" */ '../operation/supplement/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'PatchDetails',
            path: '/supplement/PatchDetails',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__supplement__ApplySupplementList__model.ts' */ 'D:/yuncut_q/src/pages/operation/supplement/ApplySupplementList/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__supplement__ApplySupplementList__PatchDetails" */ '../operation/supplement/ApplySupplementList/PatchDetails'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/supplement/ApplySupplementList/PatchDetails')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'postSale',
        icon: 'BlockOutlined',
        path: '/postSale',
        routes: [
          {
            path: '/postSale',
            redirect: '/postSale/afterSaleList',
            exact: true,
          },
          {
            name: 'postSale-list',
            path: '/postSale/afterSaleList',
            icon: 'BlockOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__afterSaleList" */ '../operation/postSale/afterSaleList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/afterSaleList').default,
            exact: true,
          },
          {
            name: 'sale_detail',
            path: '/postSale/afterSaleList/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__afterSaleList__detail" */ '../operation/postSale/afterSaleList/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/afterSaleList/detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'claimList',
            path: '/postSale/claimList',
            icon: 'FileProtectOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__claimManagement" */ '../operation/postSale/claimManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/claimManagement').default,
            exact: true,
          },
          {
            name: 'claim',
            path: '/postSale/components/claim',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__components__claim__claim" */ '../operation/postSale/components/claim/claim'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/components/claim/claim').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'FreeAudit',
            path: '/postSale/components/FreeAudit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__components__FreeAudit" */ '../operation/postSale/components/FreeAudit'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/components/FreeAudit').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'claimList_detail',
            path: '/postSale/claimListDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__claimManagement__detail" */ '../operation/postSale/claimManagement/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/claimManagement/detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'return_repair',
            path: '/postSale/return_repair',
            icon: 'InteractionOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__return_repair" */ '../operation/postSale/return_repair'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/return_repair').default,
            exact: true,
          },
          {
            name: 'missing_parts',
            path: '/postSale/missing_parts',
            icon: 'MacCommandOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__missing_parts" */ '../operation/postSale/missing_parts'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/missing_parts').default,
            exact: true,
          },
          {
            name: 'scrap_supplement',
            path: '/postSale/scrap_supplement',
            icon: 'UngroupOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__scrap_supplement" */ '../operation/postSale/scrap_supplement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/scrap_supplement').default,
            exact: true,
          },
          {
            name: 'concession_receive',
            path: '/postSale/concession_receive',
            icon: 'OneToOneOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__concession_receive" */ '../operation/postSale/concession_receive'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/concession_receive').default,
            exact: true,
          },
          {
            name: 'return_cancel',
            path: '/postSale/return_cancel',
            icon: 'ImportOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__return_cancel" */ '../operation/postSale/return_cancel'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/return_cancel').default,
            exact: true,
          },
          {
            name: 'otherClaim',
            path: '/postSale/otherClaim',
            icon: 'AppstoreOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__otherClaim" */ '../operation/postSale/otherClaim'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/otherClaim').default,
            exact: true,
          },
          {
            name: 'order_detail',
            path: '/postSale/order_detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__order_detail" */ '../operation/postSale/order_detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/order_detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'badReportList',
            path: '/postSale/badReportList',
            icon: 'BugOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__postSale__model.ts' */ 'D:/yuncut_q/src/pages/operation/postSale/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__postSale__adverseReportList" */ '../operation/postSale/adverseReportList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/postSale/adverseReportList').default,
            exact: true,
          },
        ],
      },
      {
        name: 'qualityControl',
        icon: 'project',
        path: '/qualityControl',
        routes: [
          {
            path: '/qualityControl',
            redirect: '/qualityControl/dataLibrary',
            exact: true,
          },
          {
            name: 'dataLibrary',
            icon: 'project',
            path: '/qualityControl/dataLibrary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__dataLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/dataLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__dataLibrary" */ '../operation/qualityControl/dataLibrary'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/dataLibrary').default,
            exact: true,
          },
          {
            name: 'dataLibraryDetail',
            path: '/qualityControl/dataLibrary/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__dataLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/dataLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__dataLibrary__detail" */ '../operation/qualityControl/dataLibrary/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/dataLibrary/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'share-alt',
            name: 'experienceSharing',
            path: '/qualityControl/experienceSharing',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__experienceSharing__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/experienceSharing/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__experienceSharing" */ '../operation/qualityControl/experienceSharing'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/experienceSharing')
                  .default,
            exact: true,
          },
          {
            icon: 'share-alt',
            name: 'edit_experienceSharing',
            path: '/qualityControl/components/AddShareArticle',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__components__AddShareArticle" */ '../operation/qualityControl/components/AddShareArticle'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/components/AddShareArticle')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'experienceSharing',
            path: '/qualityControl/experienceSharing/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__experienceSharing__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/experienceSharing/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__experienceSharing__detail" */ '../operation/qualityControl/experienceSharing/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/experienceSharing/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'container',
            name: 'newsCenter',
            path: '/qualityControl/newsCenter',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__newsCenter__model.tsx' */ 'D:/yuncut_q/src/pages/operation/qualityControl/newsCenter/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__newsCenter" */ '../operation/qualityControl/newsCenter'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/newsCenter').default,
            exact: true,
          },
          {
            name: 'newsCenterDetail',
            path: '/qualityControl/newsCenter/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__newsCenter__model.tsx' */ 'D:/yuncut_q/src/pages/operation/qualityControl/newsCenter/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__newsCenter__detail" */ '../operation/qualityControl/newsCenter/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/newsCenter/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'play-square',
            name: 'video',
            path: '/qualityControl/video',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__video__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/video/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__video" */ '../operation/qualityControl/video'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/video').default,
            exact: true,
          },
          {
            icon: 'calendar',
            name: 'train',
            path: '/qualityControl/train',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__train" */ '../operation/qualityControl/train'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/train').default,
            exact: true,
          },
          {
            icon: 'calendar',
            name: 'trainForm',
            path: '/qualityControl/train/form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__train__Form" */ '../operation/qualityControl/train/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/train/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'calendar',
            name: 'assessment-record',
            path: '/qualityControl/train/assessment-record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__train__assessment-record" */ '../operation/qualityControl/train/assessment-record'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/train/assessment-record')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'calendar',
            name: 'assessment-record-detail',
            path: '/qualityControl/train/assessment-record-detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__train__detail__assessment-record-detail" */ '../operation/qualityControl/train/detail/assessment-record-detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/train/detail/assessment-record-detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'spotCheck',
            path: '/qualityControl/spotCheck',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__spotCheck" */ '../operation/qualityControl/spotCheck'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/spotCheck').default,
            exact: true,
          },
          {
            icon: 'FileProtectOutlined',
            name: 'non-performingBill',
            path: '/qualityControl/nonPerformingBill',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__nonPerformingBill__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/nonPerformingBill/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__nonPerformingBill__HandsontableExcel" */ '../operation/qualityControl/nonPerformingBill/HandsontableExcel'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/nonPerformingBill/HandsontableExcel')
                  .default,
            exact: true,
          },
          {
            icon: 'FileProtectOutlined',
            name: 'non-performingBill-List',
            path: '/qualityControl/nonPerformingBillList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__nonPerformingBill__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/nonPerformingBill/List/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__nonPerformingBill__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/nonPerformingBill/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__nonPerformingBill__List__index" */ '../operation/qualityControl/nonPerformingBill/List/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/nonPerformingBill/List/index')
                  .default,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'spotCheckForm',
            path: '/qualityControl/spotCheck/form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__spotCheck__form" */ '../operation/qualityControl/spotCheck/form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/spotCheck/form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'spotCheckResult',
            path: '/qualityControl/spotCheck/spotCheckResult',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__spotCheck__spotCheckResult" */ '../operation/qualityControl/spotCheck/spotCheckResult'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/spotCheck/spotCheckResult')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'spotCheckDetail',
            path: '/qualityControl/spotCheck/spotCheckDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__spotCheck__detail" */ '../operation/qualityControl/spotCheck/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/spotCheck/detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'container',
            name: 'rejectRatio',
            path: '/qualityControl/rejectRatio',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__rejectRatio__model.tsx' */ 'D:/yuncut_q/src/pages/operation/qualityControl/rejectRatio/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__rejectRatio" */ '../operation/qualityControl/rejectRatio'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/rejectRatio').default,
            exact: true,
          },
          {
            icon: 'container',
            name: 'rejectRatioForm',
            path: '/qualityControl/rejectRatio/Form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__rejectRatio__Form__model.tsx' */ 'D:/yuncut_q/src/pages/operation/qualityControl/rejectRatio/Form/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__rejectRatio__model.tsx' */ 'D:/yuncut_q/src/pages/operation/qualityControl/rejectRatio/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__rejectRatio__Form" */ '../operation/qualityControl/rejectRatio/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/rejectRatio/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'badRateFactory',
            path: '/qualityControl/badRateFactory',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__badRateFactory__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/badRateFactory/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__badRateFactory" */ '../operation/qualityControl/badRateFactory'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/badRateFactory').default,
            exact: true,
          },
          {
            icon: 'check-square',
            name: 'badRateFactoryForm',
            path: '/qualityControl/badRateFactory/Form',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__qualityControl__badRateFactory__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/badRateFactory/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__badRateFactory__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/badRateFactory/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__qualityControl__model.ts' */ 'D:/yuncut_q/src/pages/operation/qualityControl/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__qualityControl__badRateFactory__Form" */ '../operation/qualityControl/badRateFactory/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/qualityControl/badRateFactory/Form')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'product-library',
        path: '/product-library',
        icon: 'appstore',
        routes: [
          {
            path: '/list',
            redirect: '/product-library/list',
            exact: true,
          },
          {
            name: 'list',
            icon: 'car',
            path: '/product-library/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__List" */ '../operation/productLibrary/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/List').default,
            exact: true,
          },
          {
            name: 'stock-taking-form',
            icon: 'car',
            path: '/product-library/stock-taking-form',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__StockTakingForm" */ '../operation/productLibrary/StockTakingForm'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/StockTakingForm').default,
            showBack: true,
            exact: true,
          },
          {
            name: 'product-ProductOutOperation',
            path: '/product-library/product-ProductOutOperation',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__List__ProductOutOperation" */ '../operation/productLibrary/List/ProductOutOperation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/List/ProductOutOperation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'product-ProductInOperation',
            path: '/product-library/product-ProductInOperation',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__List__ProductInOperation" */ '../operation/productLibrary/List/ProductInOperation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/List/ProductInOperation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'storehouse-in-list',
            icon: 'car',
            path: '/product-library/storehouse-in-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__StorehouseInList" */ '../operation/productLibrary/StorehouseInList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/StorehouseInList').default,
            exact: true,
          },
          {
            name: 'storehouse-in-detail',
            icon: 'car',
            path: '/product-library/storehouse-in-detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__StorehouseInList__detail" */ '../operation/productLibrary/StorehouseInList/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/StorehouseInList/detail')
                  .default,
            showBack: true,
            exact: true,
          },
          {
            name: 'productStorage',
            icon: 'car',
            path: '/product-library/productStorage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__ProductStorage__model.tsx' */ 'D:/yuncut_q/src/pages/operation/productLibrary/ProductStorage/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__ProductStorage" */ '../operation/productLibrary/ProductStorage'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/ProductStorage').default,
            exact: true,
          },
          {
            name: 'productStorageForm',
            icon: 'car',
            path: '/product-library/productStorage/Form',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__ProductStorage__Form__model.tsx' */ 'D:/yuncut_q/src/pages/operation/productLibrary/ProductStorage/Form/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__ProductStorage__model.tsx' */ 'D:/yuncut_q/src/pages/operation/productLibrary/ProductStorage/model.tsx').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__ProductStorage__Form" */ '../operation/productLibrary/ProductStorage/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/ProductStorage/Form')
                  .default,
            showBack: true,
            exact: true,
          },
          {
            name: 'storehouse-out-list',
            icon: 'car',
            path: '/product-library/storehouse-out-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__StorehouseOutList" */ '../operation/productLibrary/StorehouseOutList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/StorehouseOutList')
                  .default,
            exact: true,
          },
          {
            name: 'storehouse-out-detail',
            icon: 'car',
            hideInMenu: true,
            path: '/product-library/storehouse-out-detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__StorehouseOutList__detail" */ '../operation/productLibrary/StorehouseOutList/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/StorehouseOutList/detail')
                  .default,
            showBack: true,
            exact: true,
          },
          {
            name: 'partsPurchase-list',
            icon: 'car',
            path: '/product-library/partsPurchase-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase" */ '../operation/productLibrary/PartsPurchase'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase').default,
            exact: true,
          },
          {
            name: 'partsPurchase-detail',
            path: '/product-library/partsPurchase-detail',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__detail__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/detail/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase__detail" */ '../operation/productLibrary/PartsPurchase/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'partsPurchase-save',
            path: '/product-library/partsPurchase-save',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase__Form__partindex" */ '../operation/productLibrary/PartsPurchase/Form/partindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase/Form/partindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'partsPurchase-order',
            path: '/product-library/partsPurchase-order',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase__Form__orderindex" */ '../operation/productLibrary/PartsPurchase/Form/orderindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase/Form/orderindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'partsPurchase-warehousing',
            icon: 'experiment',
            path: '/product-library/partsPurchase-warehousing',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__part__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/part/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__PartsPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/PartsPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__PartsPurchase__part__index" */ '../operation/productLibrary/PartsPurchase/part/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/PartsPurchase/part/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'productJournal-list',
            icon: 'car',
            path: '/product-library/productJournal-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__productLibrary__ProductJournal__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/ProductJournal/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__productLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/productLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__productLibrary__ProductJournal" */ '../operation/productLibrary/ProductJournal'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/productLibrary/ProductJournal').default,
            exact: true,
          },
        ],
      },
      {
        name: 'finance',
        icon: 'DollarOutlined',
        path: '/finance',
        routes: [
          {
            path: '/finance',
            redirect: '/finance/list',
            exact: true,
          },
          {
            name: 'list',
            icon: 'DollarOutlined',
            path: '/finance/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__finance__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/List/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__finance__List" */ '../operation/finance/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/finance/List').default,
            exact: true,
          },
          {
            name: 'customer-period',
            icon: 'DollarOutlined',
            path: '/finance/customer-period',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/customer-period',
                redirect: '/finance/customer-period/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/customer-period/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CustomerPeriod__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CustomerPeriod/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__finance__CustomerPeriod__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CustomerPeriod/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CustomerPeriod/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CustomerPeriod/List').default,
                exact: true,
              },
            ],
          },
          {
            name: 'invoice-information',
            icon: 'DollarOutlined',
            path: '/finance/invoice-information',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/invoice-information',
                redirect: '/finance/invoice-information/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/invoice-information/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__InvoiceInformation__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/InvoiceInformation/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/InvoiceInformation/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/InvoiceInformation/List')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'collection-plan',
            icon: 'DollarOutlined',
            path: '/finance/collection-plan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/collection-plan',
                redirect: '/finance/collection-plan/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/List').default,
                exact: true,
              },
              {
                name: 'depositModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/depositModification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__DepositModification__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/DepositModification/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/DepositModification'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/DepositModification')
                      .default,
                exact: true,
              },
              {
                name: 'depositWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/depositWriteOff',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__DepositWriteOff__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/DepositWriteOff/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/DepositWriteOff'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/DepositWriteOff')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'progressModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/progressModification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__ProgressModification__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/ProgressModification/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/ProgressModification'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/ProgressModification')
                      .default,
                exact: true,
              },
              {
                name: 'paymentWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/paymentWriteOff',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__PaymentWriteOff__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/PaymentWriteOff/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/PaymentWriteOff'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/PaymentWriteOff')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'toBeReviewed',
                icon: 'home',
                path: '/finance/collection-plan/toBeReviewed',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__toBeReviewed__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/toBeReviewed/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/toBeReviewed'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/toBeReviewed')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'advanceReviewed',
                icon: 'home',
                path: '/finance/collection-plan/advanceReviewed',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__advanceReviewed__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/advanceReviewed/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/advanceReviewed'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/advanceReviewed')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'balanceModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/balanceModification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__balanceModification__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/balanceModification/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/balanceModification'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/balanceModification')
                      .default,
                exact: true,
              },
              {
                name: 'advanceModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/advanceModification',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__advanceModification__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/advanceModification/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/advanceModification'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/advanceModification')
                      .default,
                exact: true,
              },
              {
                name: 'splitOrder',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/splitOrder',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__SplitOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/SplitOrder/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/SplitOrder'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/SplitOrder')
                      .default,
                exact: true,
              },
              {
                name: 'balanceWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/balanceWriteOff',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__BalanceWriteOff__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/BalanceWriteOff/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/BalanceWriteOff'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/BalanceWriteOff')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'balanceSubmit',
                icon: 'home',
                path: '/finance/collection-plan/balanceSubmit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__BalanceSubmit__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/BalanceSubmit/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/BalanceSubmit'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/BalanceSubmit')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'advancePayment',
                icon: 'home',
                path: '/finance/collection-plan/advancePayment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__collectionPlan__AdvancePayment__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/collectionPlan/AdvancePayment/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/collectionPlan/AdvancePayment'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/collectionPlan/AdvancePayment')
                      .default,
                showBack: true,
                exact: true,
              },
              {
                name: 'invoiceOffset',
                icon: 'home',
                path: '/finance/collection-plan/invoiceOffset',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__CollectionPlan__InvoiceOffset__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/CollectionPlan/InvoiceOffset/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/CollectionPlan/InvoiceOffset'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/CollectionPlan/InvoiceOffset')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'payment-agreement',
            icon: 'DollarOutlined',
            path: '/finance/payment-agreement',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/payment-agreement',
                redirect: '/finance/payment-agreement/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/payment-agreement/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__finance__PaymentAgreement__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/PaymentAgreement/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__finance__PaymentAgreement__model.ts' */ 'D:/yuncut_q/src/pages/operation/finance/PaymentAgreement/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/finance/PaymentAgreement/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/finance/PaymentAgreement/List')
                      .default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'finance-report',
        icon: 'FundProjectionScreenOutlined',
        path: '/finance-report',
        routes: [
          {
            name: 'finance-produce',
            icon: 'printer',
            path: '/finance-report/finance-produce',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__produce__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/produce/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__produce" */ '../operation/financeReport/produce'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/produce').default,
            exact: true,
          },
          {
            name: 'finance-scrap',
            icon: 'printer',
            path: '/finance-report/finance-scrap',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__scrap__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/scrap/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__scrap" */ '../operation/financeReport/scrap'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/scrap').default,
            exact: true,
          },
          {
            name: 'finance-subcontract',
            icon: 'printer',
            path: '/finance-report/finance-subcontract',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__subcontract__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/subcontract/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__subcontract__index" */ '../operation/financeReport/subcontract/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/subcontract/index').default,
            exact: true,
          },
          {
            name: 'purchase-order',
            icon: 'car',
            path: '/finance-report/purchase-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__purchaseOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/purchaseOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__purchaseOrder" */ '../operation/financeReport/purchaseOrder'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/purchaseOrder').default,
            exact: true,
          },
          {
            name: 'purchase-warehouse',
            icon: 'build',
            path: '/finance-report/purchase-warehouse',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__purchaseWarehouse__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/purchaseWarehouse/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__purchaseWarehouse" */ '../operation/financeReport/purchaseWarehouse'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/purchaseWarehouse').default,
            exact: true,
          },
          {
            name: 'material_delivery',
            icon: 'experiment',
            path: '/finance-report/material_delivery',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__materialDelivery__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/materialDelivery/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__materialDelivery" */ '../operation/financeReport/materialDelivery'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/materialDelivery').default,
            exact: true,
          },
          {
            name: 'sales-order',
            icon: 'solution',
            path: '/finance-report/sales-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/salesOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__salesOrder" */ '../operation/financeReport/salesOrder'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/salesOrder').default,
            exact: true,
          },
          {
            name: 'salesPrice-order',
            icon: 'solution',
            path: '/finance-report/salesPrice-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__salesPriceOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/salesPriceOrder/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__salesPriceOrder" */ '../operation/financeReport/salesPriceOrder'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/salesPriceOrder').default,
            exact: true,
          },
          {
            name: 'sales-shipment',
            icon: 'solution',
            path: '/finance-report/sales-shipment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__salesShipment__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/salesShipment/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__salesShipment" */ '../operation/financeReport/salesShipment'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/salesShipment').default,
            exact: true,
          },
          {
            name: 'scrap-purchase',
            icon: 'solution',
            path: '/finance-report/scrap-purchase',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__scrapPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/scrapPurchase/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__scrapPurchase" */ '../operation/financeReport/scrapPurchase'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/scrapPurchase').default,
            exact: true,
          },
          {
            name: 'order-execution',
            icon: 'solution',
            path: '/finance-report/order-execution',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__orderExecution__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/orderExecution/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__orderExecution" */ '../operation/financeReport/orderExecution'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/orderExecution').default,
            exact: true,
          },
          {
            name: 'finance-ymAmbGrossProfitAnnual',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfitAnnual',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfitAnnual__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfitAnnual/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfitAnnual" */ '../operation/financeReport/ymAmbGrossProfitAnnual'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfitAnnual')
                  .default,
            exact: true,
          },
          {
            name: 'finance-ymAmbGrossProfitDept',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfitDept',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfitDept__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfitDept/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfitDept" */ '../operation/financeReport/ymAmbGrossProfitDept'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfitDept')
                  .default,
            exact: true,
          },
          {
            name: 'finance-ymAmbDeptReport',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbDeptReport',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbDeptReport__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbDeptReport/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbDeptReport" */ '../operation/financeReport/ymAmbDeptReport'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbDeptReport').default,
            exact: true,
          },
          {
            name: 'finance-ymAmbProfitReportChart',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbProfitReportChart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbProfitReportChart__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbProfitReportChart/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbProfitReportChart" */ '../operation/financeReport/ymAmbProfitReportChart'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbProfitReportChart')
                  .default,
            exact: true,
          },
          {
            name: 'finance-ymAmbGrossProfit',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfit" */ '../operation/financeReport/ymAmbGrossProfit'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfit').default,
            exact: true,
          },
          {
            name: 'ymAmbGrossProfit-transportation',
            path: '/finance-report/ymAmbGrossProfit-transportation',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfit__Form__transportationindex" */ '../operation/financeReport/ymAmbGrossProfit/Form/transportationindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfit/Form/transportationindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'ymAmbGrossProfit-overdue',
            path: '/finance-report/ymAmbGrossProfit-overdue',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfit__Form__overdueindex" */ '../operation/financeReport/ymAmbGrossProfit/Form/overdueindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfit/Form/overdueindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'ymAmbGrossProfit-claimant',
            path: '/finance-report/ymAmbGrossProfit-claimant',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__financeReport__ymAmbGrossProfit__model.ts' */ 'D:/yuncut_q/src/pages/operation/financeReport/ymAmbGrossProfit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__financeReport__ymAmbGrossProfit__Form__claimantindex" */ '../operation/financeReport/ymAmbGrossProfit/Form/claimantindex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/financeReport/ymAmbGrossProfit/Form/claimantindex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'returns',
        icon: 'box-plot',
        path: '/returns',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/returns',
            redirect: '/returns/list',
            exact: true,
          },
          {
            name: 'list',
            icon: 'box-plot',
            path: '/returns/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__returns__model.ts' */ 'D:/yuncut_q/src/pages/operation/returns/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__returns" */ '../operation/returns'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/returns').default,
            exact: true,
          },
        ],
      },
      {
        name: 'logManagement',
        icon: 'file-protect',
        path: '/logManagement',
        routes: [
          {
            path: '/logManagement',
            redirect: '/logManagement/orderLog',
            exact: true,
          },
          {
            name: 'orderLog',
            icon: 'account-book',
            path: '/logManagement/orderLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__orderLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/orderLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__orderLog" */ '../operation/logManagement/orderLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/orderLog').default,
            exact: true,
          },
          {
            name: 'XMLDetail',
            path: '/logManagement/XMLLog/XMLDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__orderLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/orderLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__orderLog__XMLDetail" */ '../operation/logManagement/orderLog/XMLDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/orderLog/XMLDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'receiptLog',
            icon: 'build',
            path: '/logManagement/receiptLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__receiptLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/receiptLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__receiptLog" */ '../operation/logManagement/receiptLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/receiptLog').default,
            exact: true,
          },
          {
            name: 'materialPurchaseLog',
            icon: 'build',
            path: '/logManagement/materialPurchaseLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__materialPurchaseLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/materialPurchaseLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__materialPurchaseLog" */ '../operation/logManagement/materialPurchaseLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/materialPurchaseLog')
                  .default,
            exact: true,
          },
          {
            name: 'partsPurchaseLog',
            icon: 'build',
            path: '/logManagement/partsPurchaseLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__partsPurchaseLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/partsPurchaseLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__partsPurchaseLog" */ '../operation/logManagement/partsPurchaseLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/partsPurchaseLog').default,
            exact: true,
          },
          {
            name: 'deliverLog',
            icon: 'car',
            path: '/logManagement/deliverLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__deliverLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/deliverLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__deliverLog" */ '../operation/logManagement/deliverLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/deliverLog').default,
            exact: true,
          },
          {
            name: 'materialCustomerLog',
            icon: 'experiment',
            path: '/logManagement/materialCustomerLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__materialCustomerLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/materialCustomerLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__materialCustomerLog" */ '../operation/logManagement/materialCustomerLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/materialCustomerLog')
                  .default,
            exact: true,
          },
          {
            name: 'unNumberReportLog',
            icon: 'apartment',
            path: '/logManagement/unNumberReportLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__unNumberReportLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/unNumberReportLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__unNumberReportLog" */ '../operation/logManagement/unNumberReportLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/unNumberReportLog').default,
            exact: true,
          },
          {
            name: 'unQualityReportLog',
            icon: 'pull-request',
            path: '/logManagement/unQualityReportLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__unQualityReportLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/unQualityReportLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__unQualityReportLog" */ '../operation/logManagement/unQualityReportLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/unQualityReportLog')
                  .default,
            exact: true,
          },
          {
            name: 'programLog',
            icon: 'windows',
            path: '/logManagement/programLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__programLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/programLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__programLog" */ '../operation/logManagement/programLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/programLog').default,
            exact: true,
          },
          {
            name: 'materialAllotLog',
            icon: 'setting',
            path: '/logManagement/materialAllotLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__materialAllotLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/materialAllotLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__materialAllotLog" */ '../operation/logManagement/materialAllotLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/materialAllotLog').default,
            exact: true,
          },
          {
            name: 'systemLog',
            icon: 'appstore',
            path: '/logManagement/systemLog',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__systemLog__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/systemLog/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__systemLog" */ '../operation/logManagement/systemLog'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/systemLog').default,
            exact: true,
          },
          {
            name: 'Interface',
            icon: 'api',
            path: '/logManagement/Interface',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__interface__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/interface/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__interface" */ '../operation/logManagement/interface'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/interface').default,
            exact: true,
          },
          {
            name: 'integrationService',
            icon: 'api',
            path: '/logManagement/integrationService',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__integrationService__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/integrationService/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__integrationService" */ '../operation/logManagement/integrationService'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/integrationService')
                  .default,
            exact: true,
          },
          {
            name: 'programChk',
            icon: 'api',
            path: '/logManagement/integrationService/programChk',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__logManagement__integrationService__model.ts' */ 'D:/yuncut_q/src/pages/operation/logManagement/integrationService/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__logManagement__integrationService__programChk" */ '../operation/logManagement/integrationService/programChk'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/logManagement/integrationService/programChk')
                  .default,
            exact: true,
          },
        ],
      },
      {
        name: 'customer',
        icon: 'usergroup-add',
        path: '/customer',
        routes: [
          {
            path: '/customer',
            redirect: '/customer/user',
            exact: true,
          },
          {
            name: 'user',
            icon: 'user-add',
            path: '/customer/user',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/user',
                redirect: '/customer/user/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/user/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__user__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/user/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/user/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/user/List').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'UserOutlined',
                path: '/customer/user/detail',
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'address',
            icon: 'environment',
            path: '/customer/address',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/address',
                redirect: '/customer/address/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/address/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__address__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/address/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/address'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/address').default,
                exact: true,
              },
            ],
          },
          {
            name: 'invoice',
            icon: 'file-protect',
            path: '/customer/invoice',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/invoice',
                redirect: '/customer/invoice/list',
                exact: true,
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/invoice/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__invoice__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/invoice/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/invoice'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/invoice').default,
                exact: true,
              },
            ],
          },
          {
            name: 'part',
            icon: 'tool',
            path: '/customer/part',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/part',
                redirect: '/customer/part/manage',
                exact: true,
              },
              {
                name: 'manage',
                icon: 'UserOutlined',
                path: '/customer/part/manage',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__part__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/part/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/part/PartManage'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/part/PartManage').default,
                exact: true,
              },
              {
                name: 'form',
                icon: 'UserOutlined',
                path: '/customer/part/form',
                showBack: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__part__PartDetails__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/part/PartDetails/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__customer__part__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/part/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/part/PartDetails'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/part/PartDetails').default,
                exact: true,
              },
              {
                name: 'detail',
                icon: 'UserOutlined',
                path: '/customer/part/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__customer__part__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/part/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/customer/part/Detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/customer/part/Detail').default,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'custom-template',
            icon: 'file-protect',
            path: '/customer/custom-template',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__customer__customTemplate__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/customTemplate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__customer__customTemplate__List" */ '../operation/customer/customTemplate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/customer/customTemplate/List').default,
            exact: true,
          },
          {
            name: 'consultService',
            icon: 'profile',
            path: '/customer/consultService',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__customer__ConsultService__model.ts' */ 'D:/yuncut_q/src/pages/operation/customer/ConsultService/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__customer__ConsultService" */ '../operation/customer/ConsultService'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/customer/ConsultService').default,
            exact: true,
          },
        ],
      },
      {
        name: 'supplier',
        path: '/supplier',
        icon: 'robot',
        routes: [
          {
            path: '/supplier',
            redirect: '/supplier/list',
            exact: true,
          },
          {
            name: 'firstContact',
            icon: 'SettingOutlined',
            path: '/supplier/firstContact',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__firstContact__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/firstContact/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__firstContact" */ '../operation/cuttingSupplier/firstContact'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/firstContact').default,
            exact: true,
          },
          {
            name: 'groupPermiss',
            icon: 'SettingOutlined',
            path: '/supplier/groupPermiss',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__groupPermiss__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/groupPermiss/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__groupPermiss" */ '../operation/cuttingSupplier/groupPermiss'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/groupPermiss').default,
            exact: true,
          },
          {
            name: 'groupMember',
            path: '/supplier/groupPermiss/groupMember',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__groupPermiss__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/groupPermiss/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__groupPermiss__Form__MemberForm" */ '../operation/cuttingSupplier/groupPermiss/Form/MemberForm'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/groupPermiss/Form/MemberForm')
                  .default,
            showBack: true,
            hideInMenu: true,
            exact: true,
          },
          {
            name: 'supFunctionManage',
            icon: 'SettingOutlined',
            path: '/supplier/supFunctionManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__supFunctionManage__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/supFunctionManage/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__supFunctionManage" */ '../operation/cuttingSupplier/supFunctionManage'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/supFunctionManage')
                  .default,
            exact: true,
          },
          {
            name: 'subAccount',
            path: '/supplier/supSubAccount',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__supSubAccount__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/supSubAccount/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__supSubAccount" */ '../operation/cuttingSupplier/supSubAccount'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/supSubAccount').default,
            showBack: true,
            exact: true,
          },
          {
            name: 'supplier',
            icon: 'SettingOutlined',
            path: '/supplier/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__CuttingSupplierManagement" */ '../operation/cuttingSupplier/CuttingSupplierManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/CuttingSupplierManagement')
                  .default,
            exact: true,
          },
          {
            name: 'supplier.detail',
            path: '/supplier/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__unitInformation__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/unitInformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__unitInformation" */ '../operation/cuttingSupplier/unitInformation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/unitInformation').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'supplierAdd',
            path: '/supplier/add',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__Form" */ '../operation/cuttingSupplier/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/Form').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'supplierAdd',
            path: '/supplier/addEquipment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__deviceInformation__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/deviceInformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__deviceInformation__addEquipment" */ '../operation/cuttingSupplier/deviceInformation/addEquipment'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/deviceInformation/addEquipment')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'supplierDevice-information',
            path: '/supplier/device-information',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__deviceInformation__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/deviceInformation/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__deviceInformation" */ '../operation/cuttingSupplier/deviceInformation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/deviceInformation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/supplier/login-information',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__CuttingSupplierLoginInfo" */ '../operation/cuttingSupplier/CuttingSupplierLoginInfo'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/CuttingSupplierLoginInfo')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/supplier/CutUnitPriceInfo',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__CutUnitPriceInfoDetail" */ '../operation/cuttingSupplier/CutUnitPriceInfoDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/CutUnitPriceInfoDetail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/supplier/FactorySupplierAdd',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__FactorySupplier" */ '../operation/cuttingSupplier/FactorySupplier'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/FactorySupplier').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            path: '/supplier/CutUnitPriceInfoCopy',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__CopySupplier" */ '../operation/cuttingSupplier/CopySupplier'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/CopySupplier').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-supplier',
            path: '/supplier/material-supplier',
            icon: 'solution',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSupplier__List" */ '../operation/materialLibrary/materialSupplier/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSupplier/List')
                  .default,
            exact: true,
          },
          {
            name: 'cameraYsList',
            path: '/supplier/cameraYsList',
            icon: 'SettingOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__CameraYsList" */ '../operation/cuttingSupplier/CameraYsList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/CameraYsList').default,
            exact: true,
          },
          {
            name: 'Op_supInvoice',
            icon: 'SettingOutlined',
            path: '/supplier/supInvoice',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__invoice__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/invoice/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__invoice" */ '../operation/cuttingSupplier/invoice'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/invoice').default,
            exact: true,
          },
          {
            name: 'Op_supAddress',
            icon: 'SettingOutlined',
            path: '/supplier/supAddress',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__address__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/address/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__cuttingSupplier__model.ts' */ 'D:/yuncut_q/src/pages/operation/cuttingSupplier/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__cuttingSupplier__address" */ '../operation/cuttingSupplier/address'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/cuttingSupplier/address').default,
            exact: true,
          },
        ],
      },
      {
        name: 'material-library',
        path: '/material-library',
        icon: 'gold',
        routes: [
          {
            name: 'material-science',
            path: '/material-library/material-science',
            icon: 'container',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialScience" */ '../operation/materialLibrary/materialScience'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialScience').default,
            exact: true,
          },
          {
            name: 'material-science-form',
            path: '/material-library/material-science-form',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__steelPlate__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/steelPlate/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialScience__steelPlate__Form__index" */ '../operation/materialLibrary/materialScience/steelPlate/Form/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialScience/steelPlate/Form/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-surplus',
            path: '/material-library/material-surplus',
            icon: 'shopping-cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSurplus/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSurplus" */ '../operation/materialLibrary/materialSurplus'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSurplus').default,
            exact: true,
          },
          {
            name: 'material-summary',
            path: '/material-library/material-statistic/material-summary',
            icon: 'shopping-cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSurplus/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSurplus__statistics__materialSummary" */ '../operation/materialLibrary/materialSurplus/statistics/materialSummary'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSurplus/statistics/materialSummary')
                  .default,
            exact: true,
          },
          {
            name: 'material-surplus-form',
            path: '/material-library/material-surplus-form',
            icon: 'fork',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSurplus/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSurplus__steelPlate__Form" */ '../operation/materialLibrary/materialSurplus/steelPlate/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSurplus/steelPlate/Form')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-allocation',
            path: '/material-library/material-allocation',
            icon: 'pull-request',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__MaterialAllocation" */ '../operation/materialLibrary/MaterialAllocation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/MaterialAllocation')
                  .default,
            exact: true,
          },
          {
            path: '/material-library/material-allocation/detail',
            icon: 'solution',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__MaterialAllocationDetail" */ '../operation/materialLibrary/MaterialAllocationDetail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/MaterialAllocationDetail')
                  .default,
            exact: true,
          },
          {
            path: '/material-library/material-allocation/add',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__AddMaterialAllocation" */ '../operation/materialLibrary/AddMaterialAllocation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/AddMaterialAllocation')
                  .default,
            exact: true,
          },
          {
            name: 'choice-issue',
            path: '/material-library/choice-issue',
            icon: 'car',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialStockOut__component__stockIndex" */ '../operation/materialLibrary/materialStockOut/component/stockIndex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialStockOut/component/stockIndex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-purchase',
            path: '/material-library/material-purchase',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-purchase',
                redirect: '/material-library/material-purchase/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-purchase/list',
                icon: 'deployment-unit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialPurchase/MaterialPurchase'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialPurchase/MaterialPurchase')
                      .default,
                exact: true,
              },
              {
                name: 'detail',
                path: '/material-library/material-purchase/detail',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__detail__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialPurchase/detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialPurchase/detail')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'save',
                path: '/material-library/material-purchase/save',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialPurchase/Form/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialPurchase/Form/index')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'saveProfile',
                path: '/material-library/material-purchase/saveProfile',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialPurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialPurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialPurchase/Form/ProfileMTForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialPurchase/Form/ProfileMTForm')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'warehousing',
                path: '/material-library/material-purchase/warehousing',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__steelPlate__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/steelPlate/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialScience/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialScience/steelPlate/Form/Purchase'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialScience/steelPlate/Form/Purchase')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'material-stockInOut',
            path: '/material-library/material-stockInOut',
            icon: 'experiment',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-stockInOut',
                redirect: '/material-library/material-stockInOut/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-stockInOut/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStockIn/InOutStock'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStockIn/InOutStock')
                      .default,
                exact: true,
              },
              {
                name: 'stockIn',
                path: '/material-library/material-stockInOut/stockIn',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStockIn'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStockIn')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stockOut',
                path: '/material-library/material-stockInOut/stockOut',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStockOut'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStockOut')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'check',
                icon: 'car',
                path: '/material-library/material-stockInOut/check',
                hideInMenu: true,
                showBack: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStockIn/StockTakingForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStockIn/StockTakingForm')
                      .default,
                exact: true,
              },
              {
                name: 'noCheck',
                icon: 'car',
                path: '/material-library/material-stockInOut/noCheck',
                hideInMenu: true,
                showBack: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__NoStockTakingForm__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/NoStockTakingForm/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStockIn/NoStockTakingForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStockIn/NoStockTakingForm')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'material-stockInItem',
            path: '/material-library/material-stockInItem',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialStockIn__detail" */ '../operation/materialLibrary/materialStockIn/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialStockIn/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-notPurchaseStockIn',
            path: '/material-library/material-notPurchaseStockIn',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockIn/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialStockIn__component__NotPurchaseStockIn" */ '../operation/materialLibrary/materialStockIn/component/NotPurchaseStockIn'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialStockIn/component/NotPurchaseStockIn')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-stockOutItem',
            path: '/material-library/material-stockOutItem',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialStockOut__detail" */ '../operation/materialLibrary/materialStockOut/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialStockOut/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-StockOutOperation',
            path: '/material-library/material-StockOutOperation',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialStockOut__component__StockOutOperation" */ '../operation/materialLibrary/materialStockOut/component/StockOutOperation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialStockOut/component/StockOutOperation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-SteelScrapStockOut',
            path: '/material-library/material-SteelScrapStockOut',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__steelScrap__StockOut__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/steelScrap/StockOut/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__steelScrap__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/steelScrap/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__steelScrap__StockOut__component__SteelScrapStockIndex" */ '../operation/materialLibrary/steelScrap/StockOut/component/SteelScrapStockIndex'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/steelScrap/StockOut/component/SteelScrapStockIndex')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-steelScrap',
            path: '/material-library/material-steelScrap',
            icon: 'solution',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSteelScrap__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSteelScrap/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSteelScrap__List" */ '../operation/materialLibrary/materialSteelScrap/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSteelScrap/List')
                  .default,
            exact: true,
          },
          {
            name: 'material-steelScrap-warehouse',
            path: '/material-library/material-steelScrap-warehouse',
            icon: 'solution',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialSteelScrapWarehouse__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialSteelScrapWarehouse/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialSteelScrapWarehouse__List" */ '../operation/materialLibrary/materialSteelScrapWarehouse/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialSteelScrapWarehouse/List')
                  .default,
            exact: true,
          },
          {
            name: 'material-customer',
            path: '/material-library/material-customer',
            icon: 'solution',
            routes: [
              {
                path: '/material-library/material-customer',
                redirect: '/material-library/material-customer/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-customer/list',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialCustomer__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialCustomer/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialCustomer/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialCustomer/List')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stock-in',
                path: '/material-library/material-customer/stock-in',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialCustomer__StockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialCustomer/StockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialCustomer/StockIn'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialCustomer/StockIn')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stock-out',
                path: '/material-library/material-customer/stock-out',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialCustomer__StockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialCustomer/StockOut/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialCustomer/StockOut'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialCustomer/StockOut')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'steel-plate-split',
            path: '/material-library/steel-plate-split',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__SteelPlateSplit__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/SteelPlateSplit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__SteelPlateSplit" */ '../operation/materialLibrary/SteelPlateSplit'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/SteelPlateSplit').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'material-reconciliation',
            path: '/material-library/material-reconciliation',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-reconciliation',
                redirect: '/material-library/material-reconciliation/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-reconciliation/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialReconciliation__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialReconciliation/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialReconciliation/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialReconciliation/index')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'material-materialStock',
            path: '/material-library/material-materialStock',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-materialStock',
                redirect: '/material-library/material-materialStock/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-materialStock/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialStock__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialStock/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialStock/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialStock/index')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'material-InventoryReport',
            path: '/material-library/material-InventoryReport',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-InventoryReport',
                redirect: '/material-library/material-InventoryReport/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/material-library/material-InventoryReport/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__materialLibrary__materialInventoryReport__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialInventoryReport/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/materialLibrary/materialInventoryReport/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/materialLibrary/materialInventoryReport/index')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'material-Journal',
            icon: 'car',
            path: '/material-library/material-Journal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialJournal__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialJournal/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialJournal" */ '../operation/materialLibrary/materialJournal'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialJournal').default,
            exact: true,
          },
          {
            name: 'material-consume',
            icon: 'car',
            path: '/material-library/material-consume',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__materialConsume__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/materialConsume/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__materialConsume" */ '../operation/materialLibrary/materialConsume'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/materialConsume').default,
            exact: true,
          },
          {
            name: 'material-weight',
            path: '/material-library/order-weight',
            icon: 'file-protect',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__materialLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/materialLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__materialLibrary__OrderWeight" */ '../operation/materialLibrary/OrderWeight'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/materialLibrary/OrderWeight').default,
            exact: true,
          },
        ],
      },
      {
        name: 'centre-library',
        path: '/centre-library',
        icon: 'gold',
        routes: [
          {
            name: 'centre-science',
            path: '/centre-library/centre-science',
            icon: 'container',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreScience" */ '../operation/centreLibrary/centreScience'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreScience').default,
            exact: true,
          },
          {
            name: 'centre-science-form',
            path: '/centre-library/centre-science-form',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__steelPlate__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/steelPlate/Form/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreScience__steelPlate__Form__index" */ '../operation/centreLibrary/centreScience/steelPlate/Form/index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreScience/steelPlate/Form/index')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-surplus',
            path: '/centre-library/centre-surplus',
            icon: 'shopping-cart',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreSurplus/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreSurplus" */ '../operation/centreLibrary/centreSurplus'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreSurplus').default,
            exact: true,
          },
          {
            name: 'centre-surplus-form',
            path: '/centre-library/centre-surplus-form',
            icon: 'fork',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreSurplus__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreSurplus/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreSurplus__steelPlate__Form" */ '../operation/centreLibrary/centreSurplus/steelPlate/Form'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreSurplus/steelPlate/Form')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-allocation',
            path: '/centre-library/centre-allocation',
            icon: 'pull-request',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__CentreAllocation" */ '../operation/centreLibrary/CentreAllocation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/CentreAllocation').default,
            exact: true,
          },
          {
            path: '/centre-library/centre-allocation/add',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__AddCentreAllocation" */ '../operation/centreLibrary/AddCentreAllocation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/AddCentreAllocation')
                  .default,
            exact: true,
          },
          {
            path: '/centre-library/centre-allocation/ExportExcel',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__transferExport__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/transferExport/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__transferExport__CentreAllocationExcel" */ '../operation/centreLibrary/transferExport/CentreAllocationExcel'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/transferExport/CentreAllocationExcel')
                  .default,
            showBack: true,
            exact: true,
          },
          {
            path: '/centre-library/centre-allocation/approveWarehoue',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__ApproveWarehoue" */ '../operation/centreLibrary/ApproveWarehoue'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/ApproveWarehoue').default,
            exact: true,
          },
          {
            path: '/centre-library/centre-allocation/refuseApproveWarehoue',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__RefuseApproveWarehoue" */ '../operation/centreLibrary/RefuseApproveWarehoue'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/RefuseApproveWarehoue')
                  .default,
            exact: true,
          },
          {
            name: 'centre-purchase',
            path: '/centre-library/centre-purchase',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-purchase',
                redirect: '/centre-library/centre-purchase/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/centre-library/centre-purchase/list',
                icon: 'deployment-unit',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centrePurchase/MaterialPurchase'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centrePurchase/MaterialPurchase')
                      .default,
                exact: true,
              },
              {
                name: 'detail',
                path: '/centre-library/centre-purchase/detail',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__detail__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centrePurchase/detail'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centrePurchase/detail')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'save',
                path: '/centre-library/centre-purchase/save',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centrePurchase/Form/materialForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centrePurchase/Form/materialForm')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'saveSurplus',
                path: '/centre-library/centre-purchase/saveSurplus',
                icon: 'gateway',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centrePurchase__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centrePurchase/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centrePurchase/Form/surplusForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centrePurchase/Form/surplusForm')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'warehousing',
                path: '/centre-library/centre-purchase/warehousing',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__steelPlate__Form__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/steelPlate/Form/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreScience__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreScience/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreScience/steelPlate/Form/Purchase'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreScience/steelPlate/Form/Purchase')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'centre-stockInOut',
            path: '/centre-library/centre-stockInOut',
            icon: 'experiment',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-stockInOut',
                redirect: '/centre-library/centre-stockInOut/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/centre-library/centre-stockInOut/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreStockIn/InOutStock'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreStockIn/InOutStock')
                      .default,
                exact: true,
              },
              {
                name: 'stockIn',
                path: '/centre-library/centre-stockInOut/stockIn',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreStockIn'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreStockIn').default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stockOut',
                path: '/centre-library/centre-stockInOut/stockOut',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreStockOut'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreStockOut')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'check',
                icon: 'car',
                path: '/centre-library/centre-stockInOut/check',
                hideInMenu: true,
                showBack: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreStockIn/StockTakingForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreStockIn/StockTakingForm')
                      .default,
                exact: true,
              },
              {
                name: 'noCheck',
                icon: 'car',
                path: '/centre-library/centre-stockInOut/noCheck',
                hideInMenu: true,
                showBack: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__NoStockTakingForm__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/NoStockTakingForm/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreStockIn/NoStockTakingForm'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreStockIn/NoStockTakingForm')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'centre-stockInItem',
            path: '/centre-library/centre-stockInItem',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockIn/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreStockIn__Detail" */ '../operation/centreLibrary/centreStockIn/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreStockIn/Detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-stockOutItem',
            path: '/centre-library/centre-stockOutItem',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreStockOut__detail" */ '../operation/centreLibrary/centreStockOut/detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreStockOut/detail')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-StockOutOperation',
            path: '/centre-library/centre-StockOutOperation',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreStockOut__component__StockOutOperation" */ '../operation/centreLibrary/centreStockOut/component/StockOutOperation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreStockOut/component/StockOutOperation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-ReturnGoodsOperation',
            path: '/centre-library/centre-ReturnGoodsOperation',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__component__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/component/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__centreStockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreStockOut/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__centreStockOut__component__ReturnGoodsOperation" */ '../operation/centreLibrary/centreStockOut/component/ReturnGoodsOperation'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/centreStockOut/component/ReturnGoodsOperation')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-customer',
            path: '/centre-library/centre-customer',
            icon: 'solution',
            routes: [
              {
                path: '/centre-library/centre-customer',
                redirect: '/centre-library/centre-customer/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/centre-library/centre-customer/list',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreCustomer__List__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreCustomer/List/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreCustomer/List'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreCustomer/List')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stock-in',
                path: '/centre-library/centre-customer/stock-in',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreCustomer__StockIn__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreCustomer/StockIn/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreCustomer/StockIn'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreCustomer/StockIn')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
              {
                name: 'stock-out',
                path: '/centre-library/centre-customer/stock-out',
                icon: 'solution',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreCustomer__StockOut__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreCustomer/StockOut/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreCustomer/StockOut'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreCustomer/StockOut')
                      .default,
                hideInMenu: true,
                showBack: true,
                exact: true,
              },
            ],
          },
          {
            name: 'steel-plate-split',
            path: '/centre-library/steel-plate-split',
            icon: 'experiment',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__centreLibrary__SteelPlateSplit__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/SteelPlateSplit/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__centreLibrary__SteelPlateSplit" */ '../operation/centreLibrary/SteelPlateSplit'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/centreLibrary/SteelPlateSplit').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'centre-InventoryReport',
            path: '/centre-library/centre-InventoryReport',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-InventoryReport',
                redirect: '/centre-library/centre-InventoryReport/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/centre-library/centre-InventoryReport/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreInventoryReport__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreInventoryReport/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreInventoryReport/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreInventoryReport/index')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'centre-centreMaterialStock',
            path: '/centre-library/centre-centreMaterialStock',
            icon: 'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-centreMaterialStock',
                redirect: '/centre-library/centre-centreMaterialStock/list',
                exact: true,
              },
              {
                name: 'list',
                path: '/centre-library/centre-centreMaterialStock/list',
                icon: 'experiment',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__operation__centreLibrary__centreMaterialStock__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/centreMaterialStock/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__operation__centreLibrary__model.ts' */ 'D:/yuncut_q/src/pages/operation/centreLibrary/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__operation__OperationLayout" */ '../operation/centreLibrary/centreMaterialStock/index'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../operation/centreLibrary/centreMaterialStock/index')
                      .default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'basic-library',
        path: '/basic-library',
        icon: 'hdd',
        routes: [
          {
            name: 'material-texture',
            icon: 'switcher',
            path: '/basic-library/material-texture',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__MaterialTexture__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/MaterialTexture/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__MaterialTexture" */ '../operation/basicLibrary/MaterialTexture'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/MaterialTexture').default,
            exact: true,
          },
          {
            name: 'plate-thickness',
            icon: 'switcher',
            path: '/basic-library/plate-thickness',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__PlateThickness__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/PlateThickness/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__PlateThickness" */ '../operation/basicLibrary/PlateThickness'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/PlateThickness').default,
            exact: true,
          },
          {
            name: 'profile-material',
            icon: 'switcher',
            path: '/basic-library/profile-material',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__profileMaterial__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/profileMaterial/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__profileMaterial" */ '../operation/basicLibrary/profileMaterial'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/profileMaterial').default,
            exact: true,
          },
          {
            name: 'cut-method',
            icon: 'switcher',
            path: '/basic-library/cut-method',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__CutMethod__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/CutMethod/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__CutMethod" */ '../operation/basicLibrary/CutMethod'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/CutMethod').default,
            exact: true,
          },
          {
            name: 'cut-seam',
            icon: 'disconnect',
            path: '/basic-library/cut-seam',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__cuttingSeam__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/cuttingSeam/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__cuttingSeam" */ '../operation/basicLibrary/cuttingSeam'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/cuttingSeam').default,
            exact: true,
          },
          {
            name: 'contract-template',
            icon: 'file-protect',
            path: '/basic-library/contract-template',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__contractTemplate__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/contractTemplate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__contractTemplate__List" */ '../operation/basicLibrary/contractTemplate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/contractTemplate/List')
                  .default,
            exact: true,
          },
          {
            name: 'quality-book',
            icon: 'disconnect',
            path: '/basic-library/quality-book',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__warrantyManagement__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/warrantyManagement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__warrantyManagement" */ '../operation/basicLibrary/warrantyManagement'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/warrantyManagement').default,
            exact: true,
          },
          {
            name: 'work-order',
            icon: 'pull-request',
            path: '/basic-library/work-order',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__WorkingProcedure__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/WorkingProcedure/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__WorkingProcedure" */ '../operation/basicLibrary/WorkingProcedure'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/WorkingProcedure').default,
            exact: true,
          },
          {
            name: 'material-brand',
            icon: 'tags',
            path: '/basic-library/material-brand',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__materialBrand__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/materialBrand/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__materialBrand" */ '../operation/basicLibrary/materialBrand'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/materialBrand').default,
            exact: true,
          },
          {
            name: 'material-standard',
            icon: 'profile',
            path: '/basic-library/material-standard',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__materialTypeStandard__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/materialTypeStandard/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__materialTypeStandard" */ '../operation/basicLibrary/materialTypeStandard'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/materialTypeStandard')
                  .default,
            exact: true,
          },
          {
            name: 'material-category',
            icon: 'switcher',
            path: '/basic-library/material-category',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__materialCategory__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/materialCategory/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__materialCategory" */ '../operation/basicLibrary/materialCategory'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/materialCategory').default,
            exact: true,
          },
          {
            name: 'postSale_badType',
            icon: 'switcher',
            path: '/basic-library/postSale_badType',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__postSaleBadType__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/postSaleBadType/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__postSaleBadType" */ '../operation/basicLibrary/postSaleBadType'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/postSaleBadType').default,
            exact: true,
          },
        ],
      },
      {
        name: 'base-price',
        path: '/base-price',
        icon: 'dollar',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__basePrice__model.ts' */ 'D:/yuncut_q/src/pages/operation/basePrice/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__basePrice__BasePriceManagement" */ '../operation/basePrice/BasePriceManagement'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/basePrice/BasePriceManagement').default,
        exact: true,
      },
      {
        name: 'base-price-save',
        icon: 'bars',
        path: '/base-price/base-price-save',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__operation__basePrice__model.ts' */ 'D:/yuncut_q/src/pages/operation/basePrice/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__operation__basePrice__material__material__List__Excelint" */ '../operation/basePrice/material/material/List/Excelint'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../operation/basePrice/material/material/List/Excelint')
              .default,
        hideInMenu: true,
        showBack: true,
        exact: true,
      },
      {
        name: 'contract-template',
        icon: 'file-protect',
        path: '/contract-template',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/contract-template',
            redirect: '/contract-template/list',
            exact: true,
          },
          {
            name: 'list',
            icon: 'UserOutlined',
            path: '/contract-template/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__contractTemplate__model.ts' */ 'D:/yuncut_q/src/pages/operation/contractTemplate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__contractTemplate__List" */ '../operation/contractTemplate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/contractTemplate/List').default,
            exact: true,
          },
        ],
      },
      {
        name: 'weight',
        icon: 'heat-map',
        path: '/weight',
        routes: [
          {
            path: '/weight',
            redirect: '/weight/type',
            exact: true,
          },
          {
            name: 'weight-type',
            path: '/weight/type',
            icon: 'heat-map',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__Weight__model.ts' */ 'D:/yuncut_q/src/pages/operation/Weight/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__Weight" */ '../operation/Weight'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/Weight').default,
            exact: true,
          },
          {
            name: 'formula-define-list',
            icon: 'bar-chart',
            path: '/weight/formula-define-list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__formulaDefine__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/formulaDefine/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__formulaDefine" */ '../operation/basicLibrary/formulaDefine'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/formulaDefine').default,
            exact: true,
          },
          {
            name: 'system-taxRate',
            icon: 'tags',
            path: '/weight/system-taxRate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__systemTaxRate__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/systemTaxRate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__systemTaxRate" */ '../operation/basicLibrary/systemTaxRate'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/systemTaxRate').default,
            exact: true,
          },
          {
            name: 'globalSetting',
            icon: 'history',
            path: '/weight/globalSetting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__globalSetting__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/globalSetting/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__globalSetting" */ '../operation/basicLibrary/globalSetting'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/globalSetting').default,
            exact: true,
          },
          {
            name: 'global-use-rate',
            icon: 'bars',
            path: '/weight/global-use-rate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__globalUseRate__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/globalUseRate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__globalUseRate__List" */ '../operation/basicLibrary/globalUseRate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/globalUseRate/List').default,
            exact: true,
          },
          {
            name: 'global-use-rate-save',
            icon: 'bars',
            path: '/weight/global-use-rate-save',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__basicLibrary__globalUseRate__model.ts' */ 'D:/yuncut_q/src/pages/operation/basicLibrary/globalUseRate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__basicLibrary__globalUseRate__List__Excelint" */ '../operation/basicLibrary/globalUseRate/List/Excelint'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/basicLibrary/globalUseRate/List/Excelint')
                  .default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'statistics',
        icon: 'laptop',
        path: '/statistics',
        routes: [
          {
            path: '/statistics',
            redirect: '/statistics/list',
            exact: true,
          },
          {
            name: 'list',
            icon: 'solution',
            path: '/statistics/list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__statistics__models__operationStatisticsView.ts' */ 'D:/yuncut_q/src/pages/operation/statistics/models/operationStatisticsView.ts').then(
                      m => {
                        return {
                          namespace: 'operationStatisticsView',
                          ...m.default,
                        };
                      },
                    ),
                    import(/* webpackChunkName: 'p__operation__statistics__models__statisticsFilter.ts' */ 'D:/yuncut_q/src/pages/operation/statistics/models/statisticsFilter.ts').then(
                      m => {
                        return { namespace: 'statisticsFilter', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__statistics__Statistics" */ '../operation/statistics/Statistics'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/statistics/Statistics').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
      {
        name: 'system',
        icon: 'laptop',
        path: '/system',
        routes: [
          {
            name: 'user',
            icon: 'user',
            path: '/system/user',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__user__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/user/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__user__List" */ '../operation/system/user/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/user/List').default,
            exact: true,
          },
          {
            name: 'role',
            icon: 'solution',
            path: '/system/role',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__role__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/role/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__role__List" */ '../operation/system/role/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/role/List').default,
            exact: true,
          },
          {
            name: 'weChatTemplate',
            icon: 'solution',
            path: '/system/weChatTemplate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__wechatTemplate__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/wechatTemplate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__wechatTemplate__List" */ '../operation/system/wechatTemplate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/wechatTemplate/List').default,
            exact: true,
          },
          {
            name: 'departmentTemplate',
            icon: 'solution',
            path: '/system/departmentTemplate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__departmentTemplate__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/departmentTemplate/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__departmentTemplate__List" */ '../operation/system/departmentTemplate/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/departmentTemplate/List').default,
            exact: true,
          },
          {
            name: 'function',
            icon: 'deployment-unit',
            path: '/system/function',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__function__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/function/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__function__List" */ '../operation/system/function/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/function/List').default,
            exact: true,
          },
          {
            name: 'interface',
            icon: 'api',
            path: '/system/interface',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__interface__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/interface/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__interface__List" */ '../operation/system/interface/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/interface/List').default,
            exact: true,
          },
          {
            name: 'announcement',
            icon: 'api',
            path: '/system/announcement',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__announcement__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/announcement/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__announcement__Index" */ '../operation/system/announcement/Index'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/announcement/Index').default,
            exact: true,
          },
          {
            name: 'statistics',
            icon: 'api',
            path: '/system/statistics',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__statistics__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/statistics/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__statistics__List" */ '../operation/system/statistics/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/statistics/List').default,
            exact: true,
          },
          {
            name: 'dictionary',
            icon: 'api',
            path: '/system/dictionary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__system__dictionary__model.ts' */ 'D:/yuncut_q/src/pages/operation/system/dictionary/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__system__dictionary__List" */ '../operation/system/dictionary/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/system/dictionary/List').default,
            exact: true,
          },
          {
            name: 'weight',
            icon: 'SettingOutlined',
            path: '/system/weight',
            exact: true,
          },
        ],
      },
      {
        name: 'setting',
        icon: 'setting',
        path: '/setting',
        routes: [
          {
            path: '/setting',
            redirect: '/setting/profile',
            exact: true,
          },
          {
            name: 'profile',
            path: '/setting/profile',
            icon: 'UserOutlined',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__operation__setting__Profile__model.ts' */ 'D:/yuncut_q/src/pages/operation/setting/Profile/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__operation__setting__Profile" */ '../operation/setting/Profile'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../operation/setting/Profile').default,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
